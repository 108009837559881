.copilotKitChat {
  z-index: 30;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
  touch-action: manipulation;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.copilotKitChat svg {
  display: inline-block;
  vertical-align: middle;
}

.copilotKitChat .copilotKitMessages {
  flex-grow: 1;
}
