.copilotKitMarkdown h1,
.copilotKitMarkdown h2,
.copilotKitMarkdown h3,
.copilotKitMarkdown h4,
.copilotKitMarkdown h5,
.copilotKitMarkdown h6 {
  font-weight: bold;
  line-height: 1.2;
}

.copilotKitMarkdown h1:not(:last-child),
.copilotKitMarkdown h2:not(:last-child),
.copilotKitMarkdown h3:not(:last-child),
.copilotKitMarkdown h4:not(:last-child),
.copilotKitMarkdown h5:not(:last-child),
.copilotKitMarkdown h6:not(:last-child) {
  margin-bottom: 1rem;
}

.copilotKitMarkdown h1 {
  font-size: 1.5em;
}

.copilotKitMarkdown h2 {
  font-size: 1.25em;
  font-weight: 600;
}

.copilotKitMarkdown h3 {
  font-size: 1.1em;
}

.copilotKitMarkdown h4 {
  font-size: 1em;
}

.copilotKitMarkdown h5 {
  font-size: 0.9em;
}

.copilotKitMarkdown h6 {
  font-size: 0.8em;
}

.copilotKitMarkdown p {
  padding: 0px;
  margin: 0px;
}

.copilotKitMarkdown p:not(:last-child),
.copilotKitMarkdown pre:not(:last-child),
.copilotKitMarkdown ol:not(:last-child),
.copilotKitMarkdown ul:not(:last-child),
.copilotKitMarkdown blockquote:not(:last-child) {
  margin-bottom: 1.25em;
}

.copilotKitMarkdown blockquote {
  border-color: rgb(142, 142, 160);
  border-left-width: 2px;
  border-left-style: solid;
  line-height: 1.2;
  padding-left: 10px;
}

.copilotKitMarkdown blockquote p {
  padding: 0.7em 0;
}

.copilotKitMarkdown ul {
  list-style-type: disc;
  padding-left: 20px;
  overflow: visible;
}

.copilotKitMarkdown li {
  list-style-type: inherit;
  list-style-position: outside;
  margin-left: 0;
  padding-left: 0;
  position: relative;
  overflow: visible;
}

.copilotKitCodeBlock {
  position: relative;
  width: 100%;
  background-color: rgb(9 9 11);
  border-radius: 0.375rem;
}

.copilotKitCodeBlockToolbar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(39 39 42);
  padding-left: 1rem;
  padding-top: 0.09rem;
  padding-bottom: 0.09rem;
  color: rgb(228, 228, 228);
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  font-family: sans-serif;
}

.copilotKitCodeBlockToolbarLanguage {
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: lowercase;
}

.copilotKitCodeBlockToolbarButtons {
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.copilotKitCodeBlockToolbarButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  height: 2.5rem;
  width: 2.5rem;
  padding: 3px;
  margin: 2px;
}

.copilotKitCodeBlockToolbarButton:hover {
  background-color: rgb(55, 55, 58);
}
