.copilotKitActivityDot1 {
  animation: copilotKitActivityDotsAnimation 1.05s infinite;
}
.copilotKitActivityDot2 {
  animation-delay: 0.1s;
}
.copilotKitActivityDot3 {
  animation-delay: 0.2s;
}
@keyframes copilotKitActivityDotsAnimation {
  0%,
  57.14% {
    animation-timing-function: cubic-bezier(0.33, 0.66, 0.66, 1);
    transform: translate(0);
  }
  28.57% {
    animation-timing-function: cubic-bezier(0.33, 0, 0.66, 0.33);
    transform: translateY(-6px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes copilotKitSpinAnimation {
  to {
    transform: rotate(360deg);
  }
}

@keyframes copilotKitPulseAnimation {
  50% {
    opacity: 0.5;
  }
}
