.copilotKitWindow {
  position: fixed;
  inset: 0px;
  transform-origin: bottom;
  border-color: rgb(229 231 235);
  background-color: rgb(255 255 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
  flex-direction: column;
  transition:
    opacity 100ms ease-out,
    transform 200ms ease-out;
  opacity: 0;
  transform: scale(0.95) translateY(20px);
  display: flex;
  pointer-events: none;
}

.copilotKitSidebar .copilotKitWindow {
  border-radius: 0;
  opacity: 1;
  transform: translateX(100%);
}

.copilotKitWindow.open {
  opacity: 1;
  transform: scale(1) translateY(0);
  pointer-events: auto;
}

.copilotKitSidebar .copilotKitWindow.open {
  transform: translateX(0);
}

@media (min-width: 640px) {
  .copilotKitWindow {
    transform-origin: bottom right;
    bottom: 5rem;
    right: 1rem;
    top: auto;
    left: auto;
    border-width: 0px;
    margin-bottom: 1rem;
    width: 24rem;
    height: 600px;
    min-height: 200px;
    max-height: calc(100% - 6rem);
  }

  .copilotKitSidebar .copilotKitWindow {
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    width: 28rem;
    min-height: 100%;
    margin-bottom: 0;
    max-height: none;
  }
}
