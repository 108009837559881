@media (min-width: 640px) {
  .custom-copilotkit {
    .copilotKitWindow {
      width: 35rem !important;
    }
    //.copilotKitMarkdown{
    //  p, a{
    //    font-family: Montserrat, sans-serif;
    //    font-size: 1.5rem;
    //  }
    //}
  }
}

.custom-copilotkit {
  .copilotKitButton{
    width: 5rem;
    height: 5rem;
  }
  .copilotKitHeader{
    font-size: 1.3rem;
  }
  .copilotKitMarkdown{
    p, a{
      font-family: Montserrat, sans-serif;
      font-size: 1.3rem;
    }
  }
  .copilotKitMessage{
    font-size: 1.3rem;
    line-height: 1.75rem;
  }
  .copilotKitMessages footer .suggestions .suggestion{
    background: var(--copilot-kit-primary-color);
    color: var(--copilot-kit-contrast-color);
    border-radius: .5rem;
    padding: 8px 12px;
    font-size: 1rem;
  }
  .copilotKitInput > textarea {
    font-size: 16px;
    line-height: 1.7rem;
  }
}

