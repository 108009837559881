.copilotKitMessages footer .suggestions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.copilotKitMessages footer h6 {
  font-weight: 500;
  font-size: 0.7rem;
  margin-bottom: 8px;
}

.copilotKitMessages footer .suggestions .suggestion {
  padding: 8px 12px;
  font-size: 0.7rem;
  border-radius: 0.5rem;
  background: var(--copilot-kit-primary-color);
  color: var(--copilot-kit-contrast-color);
}

.copilotKitMessages footer .suggestions button {
  transition: transform 0.3s ease;
}

.copilotKitMessages footer .suggestions button:not(:disabled):hover {
  transform: scale(1.05);
}

.copilotKitMessages footer .suggestions button:disabled {
  cursor: wait;
}

.copilotKitMessages footer .suggestions button svg {
  margin-right: 6px;
}
