html {
  --copilot-kit-primary-color: #3b82f6;
  --copilot-kit-contrast-color: #fff;
  --copilot-kit-secondary-color: #f3f4f6;
  --copilot-kit-secondary-contrast-color: #000;
  --copilot-kit-background-color: #fff;
  --copilot-kit-muted-color: #6a6a6a;
  --copilot-kit-separator-color: #00000014;
  --copilot-kit-scrollbar-color: #0003;
  --copilot-kit-response-button-color: #333;
  --copilot-kit-response-button-background-color: #fff;
}

.copilotKitPopup {
  z-index: 30;
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  touch-action: manipulation;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.copilotKitPopup svg {
  vertical-align: middle;
  display: inline-block;
}

.copilotKitSidebar {
  z-index: 30;
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  touch-action: manipulation;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.copilotKitSidebar svg {
  vertical-align: middle;
  display: inline-block;
}

.copilotKitSidebarContentWrapper {
  margin-right: 0;
  transition: margin-right .3s;
  overflow: visible;
}

@media (width >= 640px) {
  .copilotKitSidebarContentWrapper.sidebarExpanded {
    margin-right: 28rem;
  }
}

.copilotKitButton {
  background-color: var(--copilot-kit-primary-color);
  width: 3.5rem;
  height: 3.5rem;
  color: var(--copilot-kit-contrast-color);
  cursor: pointer;
  border: 1px solid #fff3;
  border-radius: 50%;
  outline: none;
  justify-content: center;
  align-items: center;
  transition: transform .2s;
  display: flex;
  position: relative;
  transform: scale(1);
}

.copilotKitButton:hover {
  transform: scale(1.1);
}

.copilotKitButton:active {
  transform: scale(.75);
}

.copilotKitButtonIcon {
  transition: opacity .1s, transform .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.copilotKitButton.open .copilotKitButtonIconOpen {
  opacity: 0;
  transform: translate(-50%, -50%)scale(0)rotate(90deg);
}

.copilotKitButton.open .copilotKitButtonIconClose, .copilotKitButton:not(.open) .copilotKitButtonIconOpen {
  opacity: 1;
  transform: translate(-50%, -50%)scale(1)rotate(0);
}

.copilotKitButton:not(.open) .copilotKitButtonIconClose {
  opacity: 0;
  transform: translate(-50%, -50%)scale(0)rotate(-90deg);
}

.copilotKitHeader {
  background-color: var(--copilot-kit-primary-color);
  height: 56px;
  color: var(--copilot-kit-contrast-color);
  border-bottom: 1px solid var(--copilot-kit-separator-color);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  display: flex;
  position: relative;
}

.copilotKitSidebar .copilotKitHeader {
  border-radius: 0;
}

@media (width >= 640px) {
  .copilotKitHeader {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.copilotKitHeader > button {
  color: var(--copilot-kit-contrast-color);
  cursor: pointer;
  background-color: #0000;
  border: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.copilotKitHeader > button:focus {
  outline: none;
}

.copilotKitInput {
  border-top: 1px solid var(--copilot-kit-separator-color);
  cursor: text;
  background-color: var(--copilot-kit-background-color);
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
  align-items: center;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  position: relative;
}

.copilotKitInput > .copilotKitInputControls > button {
  cursor: pointer;
  color: #00000040;
  appearance: button;
  text-transform: none;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  text-indent: 0;
  text-shadow: none;
  text-align: center;
  background-color: #0000;
  background-image: none;
  border: 0;
  margin: 0 0 0 .5rem;
  padding: .25rem;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
  transform: scale(1);
}

.copilotKitInput > .copilotKitInputControls > button:not([disabled]) {
  color: var(--copilot-kit-primary-color);
}

.copilotKitInput > .copilotKitInputControls > button:not([disabled]):hover {
  transform: scale(1.1);
}

.copilotKitInput > .copilotKitInputControls > button[disabled] {
  color: var(--copilot-kit-muted-color);
}

.copilotKitInputControls {
  display: flex;
}

.copilotKitInput > textarea {
  outline-offset: 2px;
  resize: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: text;
  font-family: inherit;
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: inherit;
  color: var(--copilot-kit-secondary-contrast-color);
  background-color: var(--copilot-kit-background-color);
  border: 0;
  outline: 2px solid #0000;
  flex: 1;
  margin: 0;
  padding: 0;
}

.copilotKitInput > textarea::placeholder {
  color: var(--copilot-kit-muted-color);
  opacity: 1;
}

.copilotKitInput > .copilotKitInputControls > button.copilotKitPushToTalkRecording {
  color: #fff;
  background-color: red;
  border-radius: 50%;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite copilotKitPulseAnimation;
}

.copilotKitMessages {
  background-color: var(--copilot-kit-background-color);
  flex-direction: column;
  flex: 1;
  padding: 1rem 2rem;
  display: flex;
  overflow-y: scroll;
}

.copilotKitMessages::-webkit-scrollbar {
  width: 9px;
}

.copilotKitMessages::-webkit-scrollbar-thumb {
  background-color: var(--copilot-kit-scrollbar-color);
  border: 2px solid var(--copilot-kit-background-color);
  border-radius: 10rem;
}

.copilotKitMessages::-webkit-scrollbar-track-piece:start {
  background: none;
}

.copilotKitMessages::-webkit-scrollbar-track-piece:end {
  background: none;
}

.copilotKitMessage {
  overflow-wrap: break-word;
  border-radius: .5rem;
  max-width: 80%;
  margin-bottom: .5rem;
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.copilotKitMessage.copilotKitUserMessage {
  background: var(--copilot-kit-primary-color);
  color: var(--copilot-kit-contrast-color);
  white-space: pre-wrap;
  margin-left: auto;
}

.copilotKitMessage.copilotKitAssistantMessage {
  background: var(--copilot-kit-secondary-color);
  color: var(--copilot-kit-secondary-contrast-color);
  margin-right: auto;
}

.copilotKitMessage.copilotKitUserMessage + .copilotKitMessage.copilotKitAssistantMessage, .copilotKitMessage.copilotKitAssistantMessage + .copilotKitMessage.copilotKitUserMessage {
  margin-top: 1.5rem;
}

.copilotKitCustomAssistantMessage {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.copilotKitMessage .inProgressLabel {
  margin-left: 10px;
}

.copilotKitMessages footer {
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
}

.copilotKitWindow {
  transform-origin: bottom;
  opacity: 0;
  pointer-events: none;
  background-color: #fff;
  border-color: #e5e7eb;
  border-radius: .75rem;
  flex-direction: column;
  transition: opacity .1s ease-out, transform .2s ease-out;
  display: flex;
  position: fixed;
  inset: 0;
  transform: scale(.95)translateY(20px);
  box-shadow: 0 5px 40px #00000029;
}

.copilotKitSidebar .copilotKitWindow {
  opacity: 1;
  border-radius: 0;
  transform: translateX(100%);
}

.copilotKitWindow.open {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1)translateY(0);
}

.copilotKitSidebar .copilotKitWindow.open {
  transform: translateX(0);
}

@media (width >= 640px) {
  .copilotKitWindow {
    transform-origin: 100% 100%;
    border-width: 0;
    width: 24rem;
    height: 600px;
    min-height: 200px;
    max-height: calc(100% - 6rem);
    margin-bottom: 1rem;
    inset: auto 1rem 5rem auto;
  }

  .copilotKitSidebar .copilotKitWindow {
    width: 28rem;
    min-height: 100%;
    max-height: none;
    margin-bottom: 0;
    inset: auto 0 0 auto;
  }
}

.copilotKitActivityDot1 {
  animation: 1.05s infinite copilotKitActivityDotsAnimation;
}

.copilotKitActivityDot2 {
  animation-delay: .1s;
}

.copilotKitActivityDot3 {
  animation-delay: .2s;
}

@keyframes copilotKitActivityDotsAnimation {
  0%, 57.14% {
    animation-timing-function: cubic-bezier(.33, .66, .66, 1);
    transform: translate(0);
  }

  28.57% {
    animation-timing-function: cubic-bezier(.33, 0, .66, .33);
    transform: translateY(-6px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes copilotKitSpinAnimation {
  to {
    transform: rotate(360deg);
  }
}

@keyframes copilotKitPulseAnimation {
  50% {
    opacity: .5;
  }
}

.copilotKitResponseButton {
  background-color: var(--copilot-kit-response-button-background-color);
  border: 1px solid var(--copilot-kit-separator-color);
  color: var(--copilot-kit-response-button-color);
  cursor: pointer;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  border-radius: 4px;
  height: 32px;
  margin: 15px 0 0;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  text-decoration: none;
}

.copilotKitResponseButton:hover {
  filter: brightness(95%);
}

.copilotKitResponseButton > span {
  margin-right: .5rem;
}

.copilotKitMarkdown h1, .copilotKitMarkdown h2, .copilotKitMarkdown h3, .copilotKitMarkdown h4, .copilotKitMarkdown h5, .copilotKitMarkdown h6 {
  font-weight: bold;
  line-height: 1.2;
}

.copilotKitMarkdown h1:not(:last-child), .copilotKitMarkdown h2:not(:last-child), .copilotKitMarkdown h3:not(:last-child), .copilotKitMarkdown h4:not(:last-child), .copilotKitMarkdown h5:not(:last-child), .copilotKitMarkdown h6:not(:last-child) {
  margin-bottom: 1rem;
}

.copilotKitMarkdown h1 {
  font-size: 1.5em;
}

.copilotKitMarkdown h2 {
  font-size: 1.25em;
  font-weight: 600;
}

.copilotKitMarkdown h3 {
  font-size: 1.1em;
}

.copilotKitMarkdown h4 {
  font-size: 1em;
}

.copilotKitMarkdown h5 {
  font-size: .9em;
}

.copilotKitMarkdown h6 {
  font-size: .8em;
}

.copilotKitMarkdown p {
  margin: 0;
  padding: 0;
}

.copilotKitMarkdown p:not(:last-child), .copilotKitMarkdown pre:not(:last-child), .copilotKitMarkdown ol:not(:last-child), .copilotKitMarkdown ul:not(:last-child), .copilotKitMarkdown blockquote:not(:last-child) {
  margin-bottom: 1.25em;
}

.copilotKitMarkdown blockquote {
  border-color: #8e8ea0;
  border-left-style: solid;
  border-left-width: 2px;
  padding-left: 10px;
  line-height: 1.2;
}

.copilotKitMarkdown blockquote p {
  padding: .7em 0;
}

.copilotKitMarkdown ul {
  padding-left: 20px;
  list-style-type: disc;
  overflow: visible;
}

.copilotKitMarkdown li {
  list-style-type: inherit;
  margin-left: 0;
  padding-left: 0;
  list-style-position: outside;
  position: relative;
  overflow: visible;
}

.copilotKitCodeBlock {
  background-color: #09090b;
  border-radius: .375rem;
  width: 100%;
  position: relative;
}

.copilotKitCodeBlockToolbar {
  color: #e4e4e4;
  background-color: #27272a;
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: .09rem;
  padding-bottom: .09rem;
  padding-left: 1rem;
  font-family: sans-serif;
  display: flex;
}

.copilotKitCodeBlockToolbarLanguage {
  text-transform: lowercase;
  font-size: .75rem;
  line-height: 1rem;
}

.copilotKitCodeBlockToolbarButtons {
  align-items: center;
  margin-left: .25rem;
  margin-right: .25rem;
  display: flex;
}

.copilotKitCodeBlockToolbarButton {
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 2px;
  padding: 3px;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  display: inline-flex;
}

.copilotKitCodeBlockToolbarButton:hover {
  background-color: #37373a;
}

.copilotKitMessages footer .suggestions {
  flex-wrap: wrap;
  gap: 8px;
  display: flex;
}

.copilotKitMessages footer h6 {
  margin-bottom: 8px;
  font-size: .7rem;
  font-weight: 500;
}

.copilotKitMessages footer .suggestions .suggestion {
  background: var(--copilot-kit-primary-color);
  color: var(--copilot-kit-contrast-color);
  border-radius: .5rem;
  padding: 8px 12px;
  font-size: .7rem;
}

.copilotKitMessages footer .suggestions button {
  transition: transform .3s;
}

.copilotKitMessages footer .suggestions button:not(:disabled):hover {
  transform: scale(1.05);
}

.copilotKitMessages footer .suggestions button:disabled {
  cursor: wait;
}

.copilotKitMessages footer .suggestions button svg {
  margin-right: 6px;
}

.copilotKitChat {
  z-index: 30;
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  touch-action: manipulation;
  flex-direction: column;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  display: flex;
}

.copilotKitChat svg {
  vertical-align: middle;
  display: inline-block;
}

.copilotKitChat .copilotKitMessages {
  flex-grow: 1;
}

.copilotKitDevConsole {
  background-color: #f3f4f6;
  border-bottom: 1px solid #cecece;
  align-items: center;
  padding: 10px;
  display: flex;
}

.copilotKitDevConsole.copilotKitDevConsoleWarnOutdated {
  background-color: #f9caca;
}

.copilotKitDevConsole .copilotKitVersionInfo {
  flex-direction: column;
  flex: 1;
  gap: 2px;
  margin-left: 18px;
  display: flex;
}

.copilotKitDevConsole .copilotKitVersionInfo header {
  font-size: 11px;
  font-weight: bold;
}

.copilotKitDevConsole .copilotKitVersionInfo footer {
  margin-top: 10px;
}

.copilotKitDevConsole .copilotKitVersionInfo footer button {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #fff;
  border: 1px solid #979797;
  border-radius: 4px;
  width: 260px;
  padding: 1px 12px 1px 5px;
  font-family: monospace;
  font-size: 11px;
  font-weight: normal;
  display: inline-block;
  overflow: hidden;
}

.copilotKitDevConsole .copilotKitVersionInfo header aside {
  color: #7f7a7a;
  margin-left: 5px;
  font-weight: normal;
  display: inline;
}

.copilotKitDevConsole .copilotKitVersionInfo section {
  font-size: 11px;
}

.copilotKitDevConsole .copilotKitVersionInfo section svg {
  margin-top: -3px;
  margin-left: 3px;
}

.copilotKitDevConsole .copilotKitDebugMenuButton {
  background-color: #d8d8d8;
  border: 1px solid #979797;
  border-radius: 6px;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 30px;
  font-size: 11px;
  font-weight: bold;
  display: flex;
}

.copilotKitDebugMenuButton.compact {
  color: #000;
  outline: none;
  justify-content: center;
  width: 35px;
  font-size: 8px;
}

.copilotKitDevConsole .copilotKitDebugMenuButton:hover {
  background-color: #c7c7c7;
}

.copilotKitDevConsole .copilotKitDebugMenuButton > svg {
  margin-left: 10px;
}

.copilotKitDebugMenu {
  background-color: #d8d8d8;
  border: 1px solid #979797;
  border-radius: 6px;
  outline: none;
  margin-top: 2px;
  padding: .25rem;
  font-size: 13px;
}

.copilotKitDebugMenuItem {
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  width: 100%;
  padding: 3px 10px;
  display: block;
}

.copilotKitDebugMenuItem:hover {
  background-color: #c7c7c7;
  border-radius: 4px;
}

.copilotKitDebugMenu[data-closed] {
  opacity: 0;
  transform: scale(.95);
}

.copilotKitDebugMenu hr {
  background-color: #979797;
  border: none;
  height: 1px;
  margin: .25rem;
}

@media (width >= 640px) {
  .custom-copilotkit .copilotKitWindow {
    width: 35rem !important;
  }
}

.custom-copilotkit .copilotKitButton {
  width: 5rem;
  height: 5rem;
}

.custom-copilotkit .copilotKitHeader {
  font-size: 1.3rem;
}

.custom-copilotkit .copilotKitMarkdown p, .custom-copilotkit .copilotKitMarkdown a {
  font-family: Montserrat, sans-serif;
  font-size: 1.3rem;
}

.custom-copilotkit .copilotKitMessage {
  font-size: 1.3rem;
  line-height: 1.75rem;
}

.custom-copilotkit .copilotKitMessages footer .suggestions .suggestion {
  background: var(--copilot-kit-primary-color);
  color: var(--copilot-kit-contrast-color);
  border-radius: .5rem;
  padding: 8px 12px;
  font-size: 1rem;
}

.custom-copilotkit .copilotKitInput > textarea {
  font-size: 16px;
  line-height: 1.7rem;
}
/*# sourceMappingURL=index.20d382e7.css.map */
