.copilotKitMessages {
  overflow-y: scroll;
  flex: 1;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  background-color: var(--copilot-kit-background-color);
}

.copilotKitMessages::-webkit-scrollbar {
  width: 9px;
}

.copilotKitMessages::-webkit-scrollbar-thumb {
  background-color: var(--copilot-kit-scrollbar-color);
  border-radius: 10rem;
  border: 2px solid var(--copilot-kit-background-color);
}

.copilotKitMessages::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

.copilotKitMessages::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

.copilotKitMessage {
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  overflow-wrap: break-word;
  max-width: 80%;
  margin-bottom: 0.5rem;
}

.copilotKitMessage.copilotKitUserMessage {
  background: var(--copilot-kit-primary-color);
  color: var(--copilot-kit-contrast-color);
  margin-left: auto;
  white-space: pre-wrap;
}

.copilotKitMessage.copilotKitAssistantMessage {
  background: var(--copilot-kit-secondary-color);
  color: var(--copilot-kit-secondary-contrast-color);
  margin-right: auto;
}

.copilotKitMessage.copilotKitUserMessage + .copilotKitMessage.copilotKitAssistantMessage {
  margin-top: 1.5rem;
}

.copilotKitMessage.copilotKitAssistantMessage + .copilotKitMessage.copilotKitUserMessage {
  margin-top: 1.5rem;
}

.copilotKitCustomAssistantMessage {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.copilotKitMessage .inProgressLabel {
  margin-left: 10px;
}

.copilotKitMessages footer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
